import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Quote from '@/components/Quote'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Right } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

const OhpeneerPage: React.FC = () => {
  const inProp = useContentInProp()
  const { t } = useTranslation()
  const ctaDetail = t('dna.sponsor_detail_cta')

  const content = {
    pretitle: t('dna.sponsor_super', 'fill copy key: dna.sponsor_super'),
    title: t('dna.sponsor_title', 'fill copy key: dna.sponsor_title'),
    text: t('dna.sponsor_body', 'fill copy key: dna.sponsor_body'),
    links: [
      {
        link: ctaDetail,
        url: '/our-dna/sponsor/marks-journey',
        variant: 'mdDown',
      },
      {
        link: t('dna.sponsor_cta', 'fill copy key: dna.sponsor_cta'),
        url: '/our-dna/ohpeneer-overview',
        variant: 'all',
      },
    ],
  }

  const QuoteComponent = (
    <Quote
      variant="light"
      name={t('dna.sponsor_quote_title')}
      jobTitle=""
      maxWidth="55rem"
      inProp={inProp}
    >
      {t('dna.sponsor_quote_body')}
    </Quote>
  )

  return (
    <>
      <Right>
        <SideNavigationLink
          variant="light"
          position="right"
          delay={1.1}
          time={1}
          url="/our-dna/sponsor/marks-journey"
          text={ctaDetail}
        />
      </Right>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box
                  col={0.6}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                >
                  {QuoteComponent}
                </Box>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default OhpeneerPage
